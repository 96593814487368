import Image from "next/image";
import {calcMiles, slugify} from "@/lib/util";

export default function ParkItem({ park, showDistance }) {
    return (
        <a href={`/park/${slugify(park.fullName)}`} className={`block h-full no-opacity-transition transition hover:scale-105 hover:shadow-lg rounded`}>
            <div className="h-full col-span-1 divide-y divide-beige-100/60 rounded bg-white">
                <div className={`w-full h-full relative flex flex-col`}>
                     {/*<span className="absolute top-2 left-2 flex-shrink-0 rounded-full bg-green-100 text-white px-3 py-1 pt-2 text-xs font-black">*/}
                     {/*   test*/}
                     {/*</span>*/}
                    <div className={`relative h-[200px] rounded`}>
                        <Image src={park.images[0].url} alt={park.images[0].altText} width="500" height="500" className="rounded-t object-cover aspect-[4/3] h-full w-full" />
                    </div>
                    <div className={`flex flex-col h-full p-5`}>
                        <h2 className={`text-xl mb-2 font-normal tracking-tight`}>{park.fullName}</h2>
                        <p className={`text-sm opacity-70`}>{park.addresses[0].city}</p>
                        {park.distanceFrom && showDistance ?
                            <p className={`mt-2 text-xs opacity-70`}>{Math.round(calcMiles(park.distanceFrom))} mi away</p>
                        : null}
                    </div>
                </div>
            </div>
        </a>
    )
}