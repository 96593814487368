import {useState} from "react";
import {AnimatePresence, motion} from "framer-motion";

import headerImage1 from '/public/homepage/header-bg-1@2x.png'
import headerImage2 from '/public/homepage/header-bg-2@2x.png'
import headerImage3 from '/public/homepage/header-bg-3@2x.png'
import headerImage4 from '/public/homepage/header-bg-4@2x.png'
import headerImage5 from '/public/homepage/hendrik-cornelissen--unsplash.png'

import { getStates, slugify } from "@/lib/util";
import TypeaheadInput from "@/components/TypeaheadInput";
import ParkItem from "@/components/ParkItem";
import {useRouter} from "next/router";
import Image from "next/image";
import HeadingStyle from "@/components/HeadingStyle";
import ParkItemsGrid from "@/components/ParkItemsGrid";
import LoaderCard from "@/components/LoaderCard";
import Link from "next/link";

import {selectStyles} from "@/lib/styles";

export default function HomepageHero({ parks, isLoading }) {
    const router = useRouter();
    const [selectedStateValue, setSelectedStateValue] = useState(null);
    const [selectedStateLabel, setSelectedStateLabel] = useState(null);

    const [selectedStateParks, setSelectedStateParks] = useState([]);
    const [selectedParkName, setSelectedParkName] = useState(null);

    let [isShowing, setIsShowing] = useState(false)
    let [isClear, setIsClear] = useState(true)

    const states = getStates()
    const parkNameOptions = [];
    parks && parks.forEach((park) => parkNameOptions.push({'value': slugify(park.fullName), 'label': park.fullName}))

    const handleFilterChange = (selectedOption, { action } ) => {
        setIsShowing(false)
        setSelectedStateValue(selectedOption ? selectedOption.value : null);
        setSelectedStateLabel(selectedOption ? selectedOption.label : null);

        if (action === 'clear') {
            setIsClear(true)
        } else {
            setIsClear(false)
        }

        if (selectedOption) {
            let selectedStateParks = parks && parks.filter(park => park.states.includes(selectedOption.value))
            setSelectedStateParks(selectedStateParks)
            setTimeout(() => {
                setIsShowing(true)
            }, 300)

            if (typeof window !== "undefined") {
                setTimeout(() => {
                    const element = document.getElementById('search-results');
                    const elementOffsetTop = element.getBoundingClientRect().top + window.scrollY;
                    if (element) window.scroll({ top: elementOffsetTop, behavior: 'smooth' })
                    element.focus()
                }, 350)
            }
        }
    };

    const handleStateFilterChange = (selectedOption) => {
        router.push(`/park/${selectedOption.value}`)
    }

    return (
        <>
            <div className={`text-center h-[515px] py-6 flex flex-col place-content-center relative`}>
                <div className="absolute top-0 z-0 w-full h-full">
                    {/*<Image src={headerImage1} alt="" width="1440" height="535" className="absolute top-0 w-full h-full object-cover" placeholder="blur" />*/}
                    {/*<Image src={headerImage2} alt="" width="1440" height="535" className="absolute top-0 w-full h-full object-cover" placeholder="blur" />*/}
                    <Image src={headerImage3} alt="" width="1440" height="535" className="absolute top-0 w-full h-full object-cover" placeholder="blur" />
                    {/*<Image src={headerImage5} alt="" width="1440" height="535" className="absolute top-0 w-full h-full object-cover" placeholder="blur" />*/}
                </div>
                <motion.h1
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.6 }}
                    className="text-[40px] leading-[45px] md:text-[53px] md:leading-[55px] italic text-white mb-5 z-1"
                >
                    Find your next<br/>national park adventure
                </motion.h1>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    className="max-w-[800px] flex flex-wrap md:flex-nowrap w-full mx-auto px-6"
                >
                    <TypeaheadInput
                        options={states}
                        value={states.filter((option) => option.value === selectedStateValue)}
                        onChange={handleFilterChange}
                        autoFocus={true}
                        isClearable={true}
                        placeholder="Search by state"
                        className={`mt-5 w-full md:w-[50%] z-2`}
                        styles={selectStyles}
                        noOptionsMessage={() => null}
                    />
                    <TypeaheadInput
                        options={parkNameOptions}
                        value={parkNameOptions.filter((option) => option.value === setSelectedParkName)}
                        onChange={handleStateFilterChange}
                        autoFocus={true}
                        isClearable={true}
                        placeholder="Search by park name"
                        className={`mt-5 w-full ml-0 md:ml-5 md:w-[50%] z-2`}
                        styles={selectStyles}
                        noOptionsMessage={() => null}
                    />
                </motion.div>

            </div>

            <Link href="/#search-results" id="search-results" className="block h-1 w-1">
                <span className="sr-only">
                    {selectedStateParks.length} parks in {selectedStateLabel}
                </span>
            </Link>
            <AnimatePresence>
                {isShowing ? (
                    <motion.section
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="max-w-8xl mx-auto my-5 px-4 sm:px-6 md:px-8"
                    >
                        {selectedStateValue && selectedStateParks &&
                            <>
                                <HeadingStyle text={`Parks in ${selectedStateLabel}`} />
                                <ParkItemsGrid items={selectedStateParks} />
                            </>
                        }
                    </motion.section>
                ) : !isClear ?
                <>
                    <section className="max-w-8xl mx-auto my-5 px-4 sm:px-6 md:px-8">
                        <LoaderCard gridItems={4} />
                    </section>
                </> : null
                }
            </AnimatePresence>

        </>
    )
}