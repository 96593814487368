import {slugify} from "@/lib/util";
import cache from "memory-cache";

export async function cachedFetchAPI(query, parameters, returnData) {
    let url;
    if (parameters) {
        url = `${process.env.PARKS_API}${query}?${parameters}&api_key=${process.env.PARKS_API_KEY}`
    } else {
        url = `${process.env.PARKS_API}${query}?api_key=${process.env.PARKS_API_KEY}`
    }
    const cachedResponse = cache.get(url);
    if (cachedResponse) {
        return cachedResponse;
    } else {
        const hours = 24;
        const response = await fetch(url, {
            method: 'GET',
            cache: 'force-cache',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'User-Agent': '*',
                'Cache-Control': 's-maxage=1440000'
            }
        });
        const json = await response.json();

        if (json.errors) {
            console.error(json.errors)
            throw new Error('Failed to fetch API')
        }
        cache.put(url, json.data, hours * 1000 * 60 * 60);
        if (returnData) {
            return json.data;
        } else {
            return json;
        }
    }
}

async function fetchAPI(query, parameters, returnData) {
    let url;
    if (parameters) {
        url = `${process.env.PARKS_API}${query}?${parameters}&api_key=${process.env.PARKS_API_KEY}`
    } else {
        url = `${process.env.PARKS_API}${query}?api_key=${process.env.PARKS_API_KEY}`
    }
    const res = await fetch(`${url}`, {
        method: 'GET',
        cache: 'force-cache',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'User-Agent': '*',
            'Cache-Control': 's-maxage=1440000'
        }
    })
    const json = await res.json()
    // if (json.errors) {
    //     console.error(json.errors)
    //     throw new Error('Failed to fetch API')
    // }
    if (returnData) {
        return json.data;
    } else {
        return json;
    }
}

export async function getAllEvents(dateStart, pageSize, pageNumber) {
    return await fetchAPI(`events`, `dateStart=${dateStart}&pageSize=${pageSize}&pageNumber=${pageNumber}`)
}

export async function getAllParks() {
    return await cachedFetchAPI(`parks`, 'limit=9999', true)
}

export async function getAllTours() {
    return await fetchAPI(`tours`, 'limit=20', true)
}

export async function getAllArticles() {
    return await fetchAPI(`articles`, 'limit=20', true)
}

export async function getAllWebcams() {
    return await fetchAPI(`webcams`, 'limit=20', true)
}

export async function getAllThingsToDo() {
    return await fetchAPI(`thingstodo`, 'limit=20', true)
}

export async function getAllParkSlugs() {
    const parks = await fetchAPI(`parks`, 'limit=9999', true)
    return parks && parks.map((park) => slugify(park.fullName))
}

export async function getAllParkCodes() {
    return await cachedFetchAPI('parks', 'limit=9999', true).then(parks => {
        //console.log(JSON.stringify(parkCode))
       // console.log(parkCode.length)
        return parks && parks.map(park => park.parkCode)
    });
}

export async function getParkCodeFromSlug(slug) {
    return await cachedFetchAPI('parks', 'limit=9999', true).then(parks => {
        const park = parks.filter(park => slugify(park.fullName) === slug.toString())
        return park && park[0].parkCode
    });
}

export async function getParkFromSlug(slug) {
    return await fetchAPI('parks', 'limit=9999', true).then(parks => {
        return parks && parks.filter(park => slugify(park.fullName) === slug)
    })
}

export async function getParkAlertsFromSlug(slug) {
    const parkCode = await getParkCodeFromSlug(slug);
    return await fetchAPI(`alerts`, `parkCode=${parkCode}`, true)
}

export async function getParkArticlesFromSlug(slug) {
    const parkCode = await getParkCodeFromSlug(slug);
    return await fetchAPI(`articles`, `limit=3&parkCode=${parkCode}`, true)
}

export async function getParkAmenitiesFromSlug(slug) {
    const parkCode = await getParkCodeFromSlug(slug);
    return await fetchAPI(`amenities`, `parkCode=${parkCode}`, true)
}

export async function getParkThingsToDoFromSlug(slug) {
    const parkCode = await getParkCodeFromSlug(slug);
    return await fetchAPI(`thingstodo`, `limit=80&parkCode=${parkCode}`, true)
}
export async function getParkCampgroundsFromSlug(slug) {
    const parkCode = await getParkCodeFromSlug(slug);
    return await fetchAPI(`campgrounds`, `limit=15&parkCode=${parkCode}`, true)
}

export async function getArticlesForHomepage() {
    return await fetchAPI(`articles`, `limit=10`, true)
}

export async function getUserLocationInfo(latitude, longitude) {
    return await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&result_type=administrative_area_level_1&key=${process.env.GOOGLE_GEOCODE_API}`)
        .then((response) => response.json())
        .then((response) => {
            console.log(response)
            return response
        })
}