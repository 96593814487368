import Head from 'next/head'
export default function Meta({ title, keywords, description, socialImg }) {
    return (
        <Head>
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <meta name='keywords' content={keywords} />
            <meta name='description' content={description} />
            <meta property="og:image" content={socialImg} />
            <meta name="og:title" content={title} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta charSet='utf-8' />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#62211e" />
            <meta name="msapplication-TileColor" content="#fff5ea" />
            <meta name="theme-color" content="#ffffff" />
            <link rel="icon" href="/favicon.ico" />
            <title>{title}</title>
        </Head>
    )
}

Meta.defaultProps = {
    title: 'national parks',
    keywords: 'national parks and stuff, other keywords',
    description: 'description',
}
