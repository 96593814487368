import HeadingStyle from "@/components/HeadingStyle";
import EventItemsGrid from "@/components/EventItemsGrid";

export default function FeaturedEvents() {
    return (
        <>
            <HeadingStyle text="Events" />
            <EventItemsGrid id="all-events" />
        </>
    )
}