import {AnimatePresence, motion} from "framer-motion";
import {slugify} from "@/lib/util";
import ParkItem from "@/components/ParkItem";
import HeadingStyle from "@/components/HeadingStyle";
import ParkItemsGrid from "@/components/ParkItemsGrid";
import LoaderCard from "@/components/LoaderCard";

export default function FeaturedParks({ parks, isLoading }) {
    // featured parks by parkCode
    const featured = [
        'blri', // blue ridge parkway
        'grsm', // great smoky mountains
        'goga', // golden gate National Recreation Area California
        'gate', // gateway National Recreation Area
        'lake', // lake mead
        'gwmp', // george washington memorial parkway
        'natr', // Natchez Trace Parkway
        'linc', // lincoln memorial
        'guis', // Gulf Islands National Seashore
        'zion', // zion national park
        'choh', // chesapeake and ohio canal
        'yell', // yellowstone national park
        'grca', // grand canyon
        'romo', // rocky mountain
        'dewa', // Delaware Water Gap National Recreation Area
        'acad', // acadia national park
        'caco', // cape cod national seashore
        'grte', // grand teton national park
        'wwii', // ww2 memorial
        'vive', // vietnam veterans memorial
        'yose', // yosemite
        'chat', // Chattahoochee River National Recreation
        'caha', //Cape Hatteras National Seashore North Carolina
        'indu', // indiana dunes national park
        'glca' // Glen Canyon National Recreation Area AZ, UT
    ]
    let featuredParks = parks && parks.filter(park => featured.includes(park.parkCode))

    return (
       <AnimatePresence>
           {isLoading ? <LoaderCard gridItems={4} /> :
               <motion.div
                   initial={{ opacity: 0 }}
                   animate={{ opacity: 1 }}
                   transition={{ delay: 1 }}
               >
                   <HeadingStyle text="Most Visited Parks" />
                   <ParkItemsGrid items={featuredParks} />
               </motion.div>
           }
           </AnimatePresence>
    )
}