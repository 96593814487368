import ParkItem from "@/components/ParkItem";

export default function ParkItemsGrid({ items, showDistance }) {
    return (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 my-5">
            {items && items.map((park) =>
                <li key={park.id} className={``}>
                    <ParkItem park={park} showDistance={showDistance} />
                </li>
            )}
        </ul>
    )
}